import _objectSpread from "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import request from '@/utils/request';
import crypto from 'crypto';
function encryption(val) {
  return crypto.createHash('md5').update(val).digest('hex');
}

// 登录
export function login(data) {
  var username = data.username,
    password = data.password,
    isWaterCat = data.isWaterCat;
  var obj = {
    'UserName': username,
    'Password': isWaterCat ? password : encryption(password),
    'LoginType': isWaterCat
  };
  return request({
    url: '/public/login',
    method: 'post',
    data: _objectSpread({}, obj)
  });
}

// 注销
export function logout() {
  return request({
    url: '/public/logout',
    method: 'post'
  });
}

// 获取信息
export function getInfo() {
  return request({
    url: '/public/info',
    method: 'post'
  });
}

// 列表
export function userList(val) {
  return request({
    url: '/user/search',
    method: 'post',
    timeout: 30000,
    data: _objectSpread({}, val)
  });
}

// 添加
export function userAdd(val) {
  var Password = val.Password;
  val.Password = encryption(Password);
  return request({
    url: '/user/add',
    method: 'post',
    data: _objectSpread({}, val)
  });
}

// 修改
export function userChange(val) {
  if (val.hasOwnProperty('Password')) {
    var Password = val.Password;
    val.Password = encryption(Password);
  }
  return request({
    url: '/user/change',
    method: 'post',
    data: _objectSpread({}, val)
  });
}

// 删除
export function userDel(val) {
  return request({
    url: '/user/del',
    method: 'post',
    data: _objectSpread({}, val)
  });
}

// 详细
export function userDetail(val) {
  return request({
    url: '/user/view',
    method: 'post',
    data: _objectSpread({}, val)
  });
}

// 导出
export function userExport(val) {
  return request({
    url: '/user/export',
    method: 'post',
    data: _objectSpread({}, val)
  });
}

// 获取下拉选项
export function userOption(val) {
  return request({
    url: '/user/option',
    method: 'post',
    data: _objectSpread({}, val)
  });
}