import Vue from 'vue';
import Router from 'vue-router';
// const _import = require('./_import_' + process.env.NODE_ENV);

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/app_download',
  component: function component() {
    return import('@/views/app_download/index');
  }
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页',
      affix: true
    },
    hidden: true
  }]
}, {
  path: '/member_index',
  component: Layout,
  redirect: '/member_index/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/member_index/index');
    },
    name: 'MemberIndex',
    meta: {
      title: '个人中心',
      icon: 'member',
      noCache: true
    }
  }]
}, {
  path: '/pdf',
  component: Layout,
  redirect: '/pdf/show',
  hidden: true,
  children: [{
    path: 'show/:pdfSrc',
    component: function component() {
      return import('@/views/pdf/show');
    },
    name: 'PDFShow',
    meta: {
      title: 'PDF打印预览',
      noCache: true
    }
  }]
}];
var createRouter = function createRouter() {
  return new Router({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export var asyncRoutes = [{
  path: '/order_add',
  component: Layout,
  // redirect: '/order_add',
  name: 'OrderAddManage',
  meta: {
    title: '我要订水',
    icon: 'cart'
  },
  children: [{
    path: 'member_order_add',
    name: 'MemberOrderAdd',
    component: function component() {
      return import('@/views/member_order_add/index');
    },
    meta: {
      title: '快速订水',
      roles: ['MemberOrderAdd']
    }
  }, {
    path: 'goods',
    name: 'Goods',
    component: function component() {
      return import('@/views/goods/index');
    },
    meta: {
      title: '商品列表',
      roles: ['Goods']
    }
  }]
}, {
  path: '/order',
  component: Layout,
  // redirect: '/member_order',
  name: 'OrderManage',
  meta: {
    title: '我的订单',
    icon: 'order'
  },
  children: [{
    path: 'member_order',
    name: 'MemberOrder',
    component: function component() {
      return import('@/views/member_order/index');
    },
    meta: {
      title: '订水订单',
      roles: ['MemberOrder']
    }
  }, {
    path: 'ticket_order',
    name: 'TicketOrder',
    component: function component() {
      return import('@/views/ticket_order/index');
    },
    meta: {
      title: '水票订单',
      roles: ['TicketOrder']
    }
  }]
}, {
  path: '/buy_ticket',
  component: Layout,
  //redirect: '/ticket/ticket',
  name: 'BuyTicket',
  meta: {
    title: '购买水票',
    icon: 'ticket'
  },
  children: [{
    path: 'index/:TicketPackageID',
    name: 'TicketOrderAdd',
    component: function component() {
      return import('@/views/ticket_order_add/index');
    },
    meta: {
      title: '购买套餐',
      roles: ['TicketOrderAdd']
    }
  }, {
    path: 'ticket_package',
    name: 'TicketPackage',
    component: function component() {
      return import('@/views/ticket_package/index');
    },
    meta: {
      title: '套餐列表',
      roles: ['TicketPackage']
    }
  }]
}, {
  path: '/points',
  component: Layout,
  redirect: '/points/points_goods',
  name: 'Points',
  meta: {
    title: '开放商城',
    icon: 'points'
  },
  children: [{
    path: 'points_goods',
    name: 'PointsGoods',
    component: function component() {
      return import('@/views/points_goods/index');
    },
    meta: {
      title: '商品管理',
      roles: ['PointsGoods']
    }
  }, {
    path: 'points_order_list',
    name: 'PointsOrderList',
    component: function component() {
      return import('@/views/points_order_list/index');
    },
    meta: {
      title: '订单管理',
      roles: ['PointsOrderList']
    }
  }, {
    path: 'points_express_record',
    name: 'PointsExpressRecord',
    component: function component() {
      return import('@/views/points_express_record/index');
    },
    meta: {
      title: '快递记录',
      roles: ['PointsExpressRecord']
    }
  }]
}, {
  path: '/water_card',
  component: Layout,
  redirect: '/water_card/water_card',
  name: 'WaterCard',
  meta: {
    title: '我的水卡',
    icon: 'points'
  },
  children: [{
    myLead: 'waterCard'
  }]
}, {
  path: '/personal_center',
  component: Layout,
  redirect: '/personal_center/ticket',
  name: 'PersonalCenterManage',
  meta: {
    title: '个人中心',
    icon: 'member-big'
  },
  children: [{
    myLead: 'ticket'
  }, {
    path: 'sub',
    name: 'Sub',
    component: function component() {
      return import('@/views/big_customer_sub/index');
    },
    meta: {
      title: '子账号管理',
      roles: ['Sub']
    }
  }, {
    path: 'sub_ticket_limit',
    name: 'SubTicketLimit',
    component: function component() {
      return import('@/views/big_customer_sub_ticket_limit/index');
    },
    meta: {
      title: '子账户额度',
      roles: ['SubTicketLimit']
    }
  }, {
    path: 'subLog',
    name: 'SubLog',
    component: function component() {
      return import('@/views/big_customer_sub_log/index');
    },
    meta: {
      title: '子账号日志',
      roles: ['SubTicketLimit']
    }
  }, {
    path: 'money',
    name: 'Money',
    component: function component() {
      return import('@/views/money/index');
    },
    meta: {
      title: '资金明细',
      roles: ['Money']
    }
  }, {
    path: 'member_invoice',
    name: 'MemberInvoice',
    component: function component() {
      return import('@/views/member_invoice/index');
    },
    meta: {
      title: '发票信息',
      roles: ['MemberInvoice']
    }
  }, {
    path: 'invoice_apply',
    name: 'InvoiceApply',
    component: function component() {
      return import('@/views/invoice_apply/index');
    },
    meta: {
      title: '发票申请',
      roles: ['InvoiceApply']
    }
  }, {
    path: 'commission',
    name: 'Commission',
    component: function component() {
      return import('@/views/commission/index');
    },
    meta: {
      title: '推广统计',
      roles: ['Commission']
    }
  }, {
    path: 'big_customer_complain',
    name: 'BigCustomerComplain',
    component: function component() {
      return import('@/views/big_customer_complain/index');
    },
    meta: {
      title: '意见反馈',
      roles: ['BigCustomerComplain']
    }
  }, {
    hidden: true,
    path: 'download_center',
    name: 'DownloadCenter',
    component: function component() {
      return import('@/views/download_center/index');
    },
    meta: {
      title: '下载中心',
      roles: ['DownloadCenter']
    }
  }]
}];

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;