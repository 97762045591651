var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "left-menu" }, [
        _c("span", { staticClass: "top-time" }, [
          _vm._v("\n            " + _vm._s(_vm.topHour)),
          _c("span", {
            staticClass: "top-time-mh",
            domProps: { innerHTML: _vm._s(_vm.topMH) },
          }),
          _vm._v(_vm._s(_vm.topMinute) + "\n        "),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "top-date" }, [_vm._v(_vm._s(_vm.topDate))]),
        _vm._v(" "),
        _c("span", { staticClass: "top-day" }, [_vm._v(_vm._s(_vm.topDay))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          [
            _c(
              "el-link",
              {
                staticClass: "right-menu-item hover-effect",
                on: { click: _vm.gotoDownload },
              },
              [_vm._v("下载中心")]
            ),
          ],
          _vm._v(" "),
          _vm.device !== "mobile"
            ? [
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
              on: { "visible-change": _vm.avatarDropdown },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.avatar === ""
                  ? _c("div", { staticClass: "user-avatar-default" }, [
                      _c("i", { staticClass: "el-icon-user-solid" }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.avatar !== ""
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.$showFileUrl(_vm.avatar), alt: "" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown my-avatar-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/member_index" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n                        个人中心\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n                        首页\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("退出登录")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }