import "core-js/modules/es6.regexp.to-string";
export function getToken() {
  return sessionStorage.getItem('token');
}
export function setToken(token) {
  return sessionStorage.setItem('token', token);
}
export function removeToken() {
  return sessionStorage.removeItem('token');
}

// 项目配置项
export function getProjectConst(key) {
  return sessionStorage.getItem(key);
}
export function setProjectConst(arr) {
  for (var key in arr) {
    sessionStorage.setItem(key, arr[key]);
  }
}

// 项目状态
export function getProjectStatus(key) {
  if (key == null) {
    return JSON.parse(sessionStorage.getItem('ProjectStatus'));
  } else {
    return JSON.parse(sessionStorage.getItem('ProjectStatus'))[key];
  }
}
export function setProjectStatus(arr) {
  sessionStorage.setItem('ProjectStatus', JSON.stringify(arr));
}

// 项目字典
export function getProjectDict(key) {
  if (key == null) {
    return JSON.parse(sessionStorage.getItem('ProjectDict'));
  } else {
    return JSON.parse(sessionStorage.getItem('ProjectDict'))[key];
  }
}
export function setProjectDict(arr) {
  sessionStorage.setItem('ProjectDict', JSON.stringify(arr));
}

//弹出窗口数组，用于展示当前最上层的弹窗样式区别
//弹出窗口数组部分开始===========================================
export function getWindowsQueue() {
  return sessionStorage.getItem('windowsQueue');
}
export function setWindowsQueue(windowsQueue) {
  return sessionStorage.setItem('windowsQueue', windowsQueue);
}
export function updateWindowsQueue(windowID) {
  var windowQueueJSON = getWindowsQueue();
  var windowQueueArr = [];
  var windowQueueArrNew = [];
  if (windowQueueJSON !== null) {
    windowQueueArr = JSON.parse(windowQueueJSON);
    for (var i = 0; i < windowQueueArr.length; i++) {
      if (windowQueueArr[i].toString() !== windowID) {
        windowQueueArrNew.push(windowQueueArr[i]);
      }
    }
  }
  return windowQueueArrNew;
}
export function addWindow(windowID) {
  var windowQueueArr = updateWindowsQueue(windowID);
  windowQueueArr.push(windowID);
  setWindowsQueue(JSON.stringify(windowQueueArr));
}
export function removeWindow(windowID) {
  var windowQueueArr = updateWindowsQueue(windowID);
  setWindowsQueue(JSON.stringify(windowQueueArr));
}
export function checkTopWindow(windowID) {
  var windowQueueJSON = getWindowsQueue();
  var windowQueueArr = [];
  if (windowQueueJSON !== null) {
    windowQueueArr = JSON.parse(windowQueueJSON);
    if (windowQueueArr.length > 0 && windowQueueArr[windowQueueArr.length - 1].toString() !== windowID) {
      return false;
    }
  }
  return true;
}

//弹出窗口数组部分结束===========================================