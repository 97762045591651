//自定义路由配置
export var leadRoutes = {
  // 我的水票
  ticket: [{
    path: 'ticket',
    name: 'Ticket',
    component: function component() {
      return import('@/views/ticket/index');
    },
    meta: {
      title: '我的水票',
      roles: ['Ticket']
    }
  }, {
    path: 'ticket_gift',
    name: 'TicketGift',
    component: function component() {
      return import('@/views/ticket_gift/index');
    },
    meta: {
      title: '水票转赠',
      roles: ['TicketGift']
    }
  }, {
    path: 'ticket_gift_detail',
    name: 'TicketGiftDetail',
    component: function component() {
      return import('@/views/ticket_gift_detail/index');
    },
    meta: {
      title: '转赠水票查询',
      roles: ['TicketGiftDetail']
    }
  }],
  // 我的水卡
  waterCard: [{
    path: 'water_card',
    name: 'WaterCard',
    component: function component() {
      return import('@/views/water_card/index');
    },
    meta: {
      title: '我的水卡',
      roles: ['WaterCard']
    }
  }, {
    path: 'water_card_assign',
    name: 'WaterCardAssign',
    component: function component() {
      return import('@/views/water_card_assign/index');
    },
    meta: {
      title: '水卡划拨',
      roles: ['WaterCardAssign']
    }
  }]
};