import "core-js/modules/es6.regexp.to-string";
import { getProjectStatus } from '@/utils/auth';

// 状态参数配置
export var types = {};

// 获取值或名称 primary、success、warning、danger、info;
export function typesValOrLabel(mode, valOrName, kind, showStatusBadge) {
  var rowsMode = getProjectStatus(mode);
  if (rowsMode !== undefined && valOrName !== undefined) {
    if (rowsMode.length > 0) {
      for (var i = 0; i < rowsMode.length; i++) {
        if (kind === undefined) {
          if (rowsMode[i].label.toString() === valOrName.toString()) {
            return rowsMode[i].value;
          }
        } else {
          if (rowsMode[i].value.toString() === valOrName.toString()) {
            var statusLabel1 = '';
            var statusLabel2 = '';
            if (showStatusBadge === undefined) {
              showStatusBadge = true;
            }
            if (rowsMode[i]['statusBadge'] !== undefined && rowsMode[i]['statusBadge'] !== '' && showStatusBadge === true) {
              statusLabel1 = '<span class="badge-status status-' + rowsMode[i]['statusBadge'] + '"><span class="badge-status-dot badge-status-' + rowsMode[i]['statusBadge'] + '"></span>';
              statusLabel2 = '</span>';
            }
            return statusLabel1 + rowsMode[i].label + statusLabel2;
          }
        }
      }
    }
  }
  if (kind === undefined) {
    return 0;
  }
  return '未知';
}

// 返回信息
export function typesArr(mode) {
  if (mode !== undefined) {
    var rowsMode = getProjectStatus(mode);
    if (rowsMode !== undefined) {
      if (rowsMode.length > 0) {
        return rowsMode;
      }
    }
    return [];
  } else {
    // 未传mode值时，可使用this.$typesArr().mode来获取$typesArr
    return getProjectStatus();
  }
}

// 类型值
export function typesVal(mode, label) {
  return typesValOrLabel(mode, label);
}

// 类型名称
export function typesLabel(mode, value, showStatusBadge) {
  return typesValOrLabel(mode, value, 1, showStatusBadge);
}