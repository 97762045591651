import "core-js/modules/es6.regexp.to-string";
import { formatTime } from '@/utils';

//格式化时间
export var filterTime = function filterTime(val) {
  return formatTime(val);
};

//四舍五入保留两位小数
export var filterNum = function filterNum(value) {
  var realVal;
  if (!isNaN(value) && value !== '') {
    // 截取当前数据到小数点后两位
    realVal = parseFloat(value).toFixed(2);
  } else {
    realVal = '--';
  }
  return realVal;
};

//去掉小数后多余的 0
export var filterCutZero = function filterCutZero(old) {
  old = old.toString();
  if (old.indexOf('.') > -1) {
    var newStr = old;
    var leng = old.length - old.indexOf('.') - 1;
    for (var i = leng; i > 0; i--) {
      //如果newStr末尾有0
      if (newStr.lastIndexOf('0') > -1 && newStr.substr(newStr.length - 1, 1) === '0') {
        var k = newStr.lastIndexOf('0');
        //如果小数点后只有一个0 去掉小数点
        if (newStr.charAt(k - 1) === '.') {
          return newStr.substring(0, k - 1);
        } else {
          //否则 去掉一个0
          newStr = newStr.substring(0, k);
        }
      } else {
        //如果末尾没有0
        return newStr;
      }
    }
  }
  return old;
};