import defaultSettings from '@/settings';
import { getProjectConst } from '@/utils/auth';
var title = defaultSettings.title || 'Vue Admin Template';

// 获取系统自定义配置
title = getProjectConst('Admin_Vip_System_Name') !== '' && getProjectConst('Admin_Vip_System_Name') !== null ? getProjectConst('Admin_Vip_System_Name') : title;
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }
  return "".concat(title);
}