import "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/core-js/modules/es6.promise.js";
import "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon
import '@/permission'; // permission control
import * as filters from '@/filter/filter';

// 自定义部分开始================
import '@/styles/theme/index.css'; // 自定义主题
// 弹出窗口拖拽
import "./utils/directives.js";
// font-awesome图标库
import 'font-awesome/css/font-awesome.css';
// 自定义样式
import '@/styles/my-public.scss';
// 公共函数
import { formatTime, formatJson, myMsg, showVal, verifyMobileFormat, inputToInt, inputToFloat, inputToPrice, jsonFormat } from "./utils/index.js";
Vue.prototype.$formatTime = formatTime;
Vue.prototype.$formatJson = formatJson;
Vue.prototype.$myMsg = myMsg;
Vue.prototype.$myNotify = myNotify;
Vue.prototype.$showVal = showVal;
Vue.prototype.$verifyMobileFormat = verifyMobileFormat;
Vue.prototype.$inputToInt = inputToInt;
Vue.prototype.$inputToFloat = inputToFloat;
Vue.prototype.$inputToPrice = inputToPrice;
Vue.prototype.$jsonFormat = jsonFormat;

//复制文本
import { copy, copyBtn } from "./utils/clipboard";
Vue.prototype.$copy = copy;
Vue.prototype.$copyBtn = copyBtn;

// 类型配置
import { typesArr, typesVal, typesLabel } from "./utils/types.js";
Vue.prototype.$typesArr = typesArr;
Vue.prototype.$typesVal = typesVal;
Vue.prototype.$typesLabel = typesLabel;

// 项目配置
import { config, showFileUrl } from "./const/config";
Vue.prototype.$config = config;
Vue.prototype.$showFileUrl = showFileUrl;
import { getProjectConst, getProjectDict } from '@/utils/auth';
import { myNotify } from "./utils/index";
Vue.prototype.$getProjectConst = getProjectConst;
Vue.prototype.$getProjectDict = getProjectDict;

// ========= 提示音(播放提示音需要用户与页面有点击交互)

// 新订单提示音
var newOrderAudio = new Audio();
newOrderAudio.src = require("./assets/audio/new_order.mp3");
Vue.prototype.$newOrderAudio = newOrderAudio;

// ========= 提示音部分结束

// 自定义部分结束================
for (var key in filters) {
  Vue.filter(key, filters[key]);
}

// set ElementUI lang to EN
Vue.use(ElementUI, {
  locale: locale
});
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});