import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  return format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
}

/**
 * @param {number} time 时间戳
 * @param {string} option 格式
 * @param {boolean} showDate 是否严格显示时间 默认:true
 * @param {boolean} notZero 是否非零 默认:true --
 * @returns {string}
 */
export function formatTime(time, option, showDate, notZero) {
  if ((notZero === undefined || notZero === true) && time === 0) {
    return '--';
  }
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  //是否严格展示
  if (showDate !== undefined && showDate === false) {
    if (diff < 30) {
      return '刚刚';
    } else if (diff < 3600) {
      // less 1 hour
      return Math.ceil(diff / 60) + '分钟前';
    } else if (diff < 3600 * 24) {
      return Math.ceil(diff / 3600) + '小时前';
    } else if (diff < 3600 * 24 * 2) {
      return '1天前';
    }
  }
  if (option === undefined) {
    option = '{y}-{m}-{d} {h}:{i}:{s}';
  }
  if (option === '年月日时分') {
    return d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  } else {
    return parseTime(time, option);
  }
}

/**
 * @param {number} time 时间戳
 * @returns {string}
 */
export function showFormatTime(time) {
  return this.$formatTime(time, '{y}-{m}-{d} {h}:{i}:{s}', true, true);
}

/**
 * @param {number} time 时间戳
 * @returns {string}
 */
export function showFormatDate(time) {
  return this.$formatTime(time, '{y}-{m}-{d}', true, true);
}

/**
 * @param {number} time 时间戳
 * @returns {string}
 */
export function showFormatHis(time) {
  return this.$formatTime(time, '{h}:{i}:{s}', true, true);
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} str value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) {
      s++;
    } else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

/**
 * Add class to element
 * @param ele
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}

/**
 * Remove class from element
 * @param ele
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

/* 自定义封装方法*/

/* JSON转换*/
export function formatJson(filterVal, jsonData) {
  return jsonData.map(function (v) {
    return filterVal.map(function (j) {
      if (j === 'timestamp') {
        return parseTime(v[j]);
      } else {
        return v[j];
      }
    });
  });
}

// 消息提示
export function myMsg(msg, type) {
  var msgType = 'info';
  if (type === 'success' || type === 'warning' || type === 'error') {
    msgType = type;
  }
  if (msg === 'Token验证错误') {
    msg = '您登录超时，请重新登录！';
  }
  this.$message({
    message: msg,
    type: msgType
    // offset:100
  });
}

/**
 * 通知
 * @param options 对应element-ui的notification组件
 * @param elementID 通知内容ID，默认空
 * @param isChange 是否修改内容，默认false（true:不会关闭通知，仅修改通知内容；false:重新弹出通知）
 */
export function myNotify(options, elementID, isChange) {
  var reNotify = true; // 是否重新弹出通知

  if (elementID === undefined) {
    elementID = '';
  }
  if (isChange === undefined) {
    isChange = false;
  }
  if (isChange) {
    var element = document.getElementById(elementID);
    if (element === undefined || element === null) {
      reNotify = true;
    } else {
      reNotify = false;
      element.innerHTML = options.message;
    }
  }
  if (reNotify) {
    this.$notify({
      title: options.title,
      message: isChange === true ? '<div id="' + elementID + '">' + options.message + '</div>' : options.message,
      dangerouslyUseHTMLString: isChange === true ? true : options.dangerouslyUseHTMLString,
      type: options.type,
      iconClass: options.iconClass,
      customClass: options.customClass,
      duration: options.duration,
      position: options.position,
      showClose: options.showClose,
      onClose: options.onClose,
      onClick: options.onClick,
      offset: options.offset
    });
  }
}

// 获取值 undefined 或 '' 返回 defaultVal
export function showVal(value, defaultVal) {
  if (defaultVal === undefined) {
    defaultVal = '--';
  }
  if (value === undefined || value === '' || value === null) {
    return defaultVal;
  }

  //将\r\n替换成<br/>
  if (typeof value == 'string') {
    value = value.toString().replace(/\r\n/g, '<br/>');
    value = value.toString().replace(/\n/g, '<br/>');
  }
  return value;
}
export function showNum(value, defaultVal) {
  if (defaultVal === undefined) {
    defaultVal = 0;
  }
  value = parseFloat(value);
  if (isNaN(value)) {
    return defaultVal;
  }
  return value;
}

/****************************************************
 【函数作用】：检测手机号码是否合法
 【参数】： mobile：需要检测的手机号
 【返回值】：合法true，不合法false
 ****************************************************/
export function verifyMobileFormat(mobile) {
  return /^[1]([3-9])[0-9]{9}$/g.test(mobile);
}

/****************************************************
 【函数作用】：将某字符串转换为整型数字
 【参数】：value：需要转换或过滤的串
 【返回值】：转换结果
 ****************************************************/
export function inputToInt(value) {
  return value.toString().replace(/\D/gi, '');
}

/****************************************************
 【函数作用】：将某字符串转换为浮点型数字
 【参数】：value：需要转换或过滤的串
 【返回值】：转换结果
 ****************************************************/
export function inputToFloat(value) {
  value = value.replace(/[^\d.]/g, '');
  value = value.replace(/\.{2,}/g, '.');
  value = value.replace(/^\./g, '0.');
  value = value.replace(/^\d*\.\d*\./g, value.substring(0, value.length - 1));
  value = value.replace(/^0[^\.]+/g, '0');
  value = value.replace(/^(\d+)\.(\d+).*$/, '$1.$2');
  return value;
}

/****************************************************
 【函数作用】：将某字符串转换为浮点型数字(保留4位小数)
 【参数】：value：需要转换或过滤的串
 【返回值】：转换结果
 ****************************************************/
export function inputToPrice(value) {
  value = inputToFloat(value);
  value = value.replace(/^(\d+)\.(\d\d).*$/, '$1.$2');
  return value;
}

/****************************************************
 【函数作用】：将某字符串转换为浮点型数字(保留n位小数)
 【参数】：value：需要转换或过滤的串，count：小数位数
 【返回值】：转换结果
 ****************************************************/
export function inputToFloatCount(value) {
  var count = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  value = inputToFloat(value);
  value = value.replace(eval("/^(\\d+)\\.(\\d{" + count + "}).*$/"), '$1.$2');
  return value;
}

//格式化json代码
export function jsonFormat(json, options) {
  var reg = null,
    formatted = '',
    pad = 0,
    PADDING = '    ';
  options = options || {};
  options.newlineAfterColonIfBeforeBraceOrBracket = options.newlineAfterColonIfBeforeBraceOrBracket === true;
  options.spaceAfterColon = options.spaceAfterColon !== false;
  if (typeof json !== 'string') {
    json = JSON.stringify(json);
  } else {
    json = JSON.parse(json);
    json = JSON.stringify(json);
  }
  reg = /([\{\}])/g;
  json = json.replace(reg, '\r\n$1\r\n');
  reg = /([\[\]])/g;
  json = json.replace(reg, '\r\n$1\r\n');
  reg = /(\,)/g;
  json = json.replace(reg, '$1\r\n');
  reg = /(\r\n\r\n)/g;
  json = json.replace(reg, '\r\n');
  reg = /\r\n\,/g;
  json = json.replace(reg, ',');
  if (!options.newlineAfterColonIfBeforeBraceOrBracket) {
    reg = /\:\r\n\{/g;
    json = json.replace(reg, ':{');
    reg = /\:\r\n\[/g;
    json = json.replace(reg, ':[');
  }
  if (options.spaceAfterColon) {
    reg = /\:/g;
    json = json.replace(reg, ':');
  }
  json.split('\r\n').forEach(function (node, index) {
    var i = 0,
      indent = 0,
      padding = '';
    if (node.match(/\{$/) || node.match(/\[$/)) {
      indent = 1;
    } else if (node.match(/\}/) || node.match(/\]/)) {
      if (pad !== 0) {
        pad -= 1;
      }
    } else {
      indent = 0;
    }
    for (i = 0; i < pad; i++) {
      padding += PADDING;
    }
    formatted += padding + node + '\r\n';
    pad += indent;
  });
  return formatted;
}

/****************************************************
 【函数作用】：将字符串切割为数组
 【参数】：str：待处理字符串
 【参数】：separator：切割
 【参数】：isNumber：是否转为整型数组
 【返回值】：结果
 ****************************************************/
export function strToArray(str, separator, isNumber) {
  if (str === undefined) {
    str = '';
  }
  if (separator === undefined) {
    separator = ',';
  }
  if (isNumber === undefined) {
    isNumber = false;
  }
  if (str.length > 0) {
    var arr = str.split(separator);
    if (isNumber) {
      return arr.map(Number);
    }
    return arr;
  }
  return [];
}

/****************************************************
 【函数作用】：判断字符串是否为JSON格式
 【参数】：str：待处理字符串
 【返回值】：合法true，不合法false
 ****************************************************/
export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      return !!(_typeof(obj) == 'object' && obj);
    } catch (e) {
      return false;
    }
  }
  return false;
}

/****************************************************
 【函数作用】：把一个数字补足几位 不足的在前面补零
 【参数】：num：待处理数字
 【参数】：length：目标字符串位数
 【返回值】：补齐位数的字符串
 ****************************************************/
export function formatNumToLength(num, length) {
  var tempNum = '';
  var numStr = num + '';
  for (var i = 1; i <= length - numStr.length; i++) {
    tempNum += '0';
  }
  tempNum += numStr;
  return tempNum;
}

// 浮点型乘法
export function numberMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

// 除数，被除数， 保留的小数点后的位数
// export function numberDiv(arg1, arg2, digit) {
//     let t1 = 0, t2 = 0, r1, r2
//     try {
//         t1 = arg1.toString().split(".")[1].length
//     } catch (e) {
//     }
//     try {
//         t2 = arg2.toString().split(".")[1].length
//     } catch (e) {
//     }
//     r1 = Number(arg1.toString().replace(".", ""))
//     r2 = Number(arg2.toString().replace(".", ""))
//     //获取小数点后的计算值
//     const result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString();
//     let result2 = result.split(".")[1];
//     result2 = result2.substring(0, digit > result2.length ? result2.length : digit);
//
//     return Number(result.split(".")[0] + "." + result2);
// }

export function numberAdd(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m + arg2 * m) / m).toFixed(n);
}

// export function NumberSub(arg1, arg2) {
//     let re1, re2, m, n
//     try {
//         re1 = arg1.toString().split(".")[1].length;
//     } catch (e) {
//         re1 = 0;
//     }
//     try {
//         re2 = arg2.toString().split(".")[1].length;
//     } catch (e) {
//         re2 = 0;
//     }
//     m = Math.pow(10, Math.max(re1, re2));
//     n = (re1 >= re2) ? re1 : re2;
//     return ((arg1 * m - arg2 * m) / m).toFixed(n);
// }

// 保留小数位（截取保留）
export function getBit(num, bit) {
  return Math.floor(num * Math.pow(10, bit)) / Math.pow(10, bit);
}

// ==============================兼容高德地图相关方法=======================
// 百度经纬度转高德（返回Arr适用高德）
export function bmToAm(lng, lat) {
  if (!lng || !lat) {
    return [0, 0];
  }
  var X_PI = Math.PI * 3000.0 / 180.0;
  var x = lng - 0.0065;
  var y = lat - 0.006;
  var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
  var gg_lng = z * Math.cos(theta);
  var gg_lat = z * Math.sin(theta);
  return [gg_lng, gg_lat];
  // return [getBit(gg_lng,6), getBit(gg_lat,6)]
}

// 高德经纬度转百度（返回Obj适用百度）
export function amToBm(lng, lat) {
  var X_PI = Math.PI * 3000.0 / 180.0;
  var x = lng,
    y = lat;
  var z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * X_PI);
  var theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * X_PI);
  var bd_lng = z * Math.cos(theta) + 0.0065;
  var bd_lat = z * Math.sin(theta) + 0.006;
  return {
    // lat: bd_lat,
    // lng: bd_lng
    lat: getBit(bd_lat, 6),
    lng: getBit(bd_lng, 6)
  };
}

// 百度数据格式转高德数据格式
export function bmDataToAmData(type, data, def) {
  try {
    switch (type) {
      case 'p':
        data = bmToAm(data.lng, data.lat);
        break;
      case 'plg':
        var tempPlg = [];
        data = JSON.parse(data);
        data.forEach(function (item) {
          tempPlg.push(bmToAm(item.lng, item.lat));
        });
        data = tempPlg;
        break;
      case 'pll':
        var tempPll = [];
        data.forEach(function (item) {
          tempPll.push(bmToAm(item.lng, item.lat));
        });
        data = tempPll;
        break;
    }
    return data;
  } catch (e) {
    if (def) {
      return def;
    } else {
      switch (type) {
        case 'marker':
          return [0, 0];
        case 'polygon':
          return [];
        case 'polyline':
          return [];
      }
    }
  }
}

// 高德数据格式转百度数据格式
export function amDataToBmData(type, data, def) {
  try {
    switch (type) {
      case 'p':
        data = amToBm(data[0], data[1]);
        break;
      case 'plg':
        var tempPlg = [];
        // data = JSON.parse(data)
        data.forEach(function (item) {
          tempPlg.push(amToBm(item[0], item[1]));
        });
        data = tempPlg;
        break;
      case 'pll':
        var tempPll = [];
        data.forEach(function (item) {
          tempPll.push(amToBm(item[0], item[1]));
        });
        data = tempPll;
        break;
    }
    return data;
  } catch (e) {
    if (def) {
      return def;
    } else {
      switch (type) {
        case 'marker':
          return {
            lat: 0,
            lng: 0
          };
        case 'polygon':
          return [];
        case 'polyline':
          return [];
      }
    }
  }
}

// 下划线转大驼峰
export function toCamelCase(input) {
  return input.split('_').map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join('');
}

/**
 * 获取操作系统
 * @returns {string}
 */
export function getOS() {
  var u = navigator.userAgent;
  if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
    return 'Windows';
  } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
    return 'MacOS';
  } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
    return 'iOS';
  } else if (u.match(/android/i)) {
    return 'Android';
  } else if (u.match(/Ubuntu/i)) {
    return 'Ubuntu';
  } else {
    return 'Unknown';
  }
}

/**
 * 获得浏览器名称
 * @returns {string}
 */
export function getBrowserName() {
  var userAgent = navigator.userAgent;
  if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    return 'Opera';
  } else if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1) {
    return 'IE';
  } else if (userAgent.indexOf("Edge") > -1) {
    return 'Edge';
  } else if (userAgent.indexOf("Firefox") > -1) {
    return 'Firefox';
  } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
    return 'Safari';
  } else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1) {
    return 'Chrome';
  } else if (!!window.ActiveXObject || "ActiveXObject" in window) {
    return 'IE>=11';
  } else {
    return 'Unknown';
  }
}