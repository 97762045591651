exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".tags-view-wrapper .tags-view-item .el-icon-close{width:16px;height:16px;vertical-align:1px;border-radius:50%;text-align:center;-webkit-transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);transition:all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);-webkit-transform-origin:100% 50%;transform-origin:100% 50%;margin-left:10px;font-weight:bolder}.tags-view-wrapper .tags-view-item .el-icon-close:before{-webkit-transform:scale(0.8);transform:scale(0.8);display:inline-block;vertical-align:-1px}.tags-view-wrapper .tags-view-item .el-icon-close:hover{background-color:#4865f5;color:#fff}.tags-view-wrapper .tags-view-item .el-icon-close-space{width:6px;height:16px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#b1bbf9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#4865f5",
	"menuHover": "#4254dc",
	"subMenuBg": "#4254dc",
	"subMenuHover": "#3b4dd9",
	"sideBarWidth": "240px"
};