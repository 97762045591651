var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm._v(" "),
      _c(
        "a",
        {
          class: "customer-service animated " + _vm.animationName,
          on: { click: _vm.weChatMessageReply },
        },
        [
          _vm.unreadWeChatMessageCount > 0
            ? _c(
                "el-badge",
                { attrs: { value: _vm.unreadWeChatMessageCount, max: 99 } },
                [_c("i", { staticClass: "el-icon-service" })]
              )
            : _c("i", { staticClass: "el-icon-service" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }