import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import Vue from 'vue';
import { PopupManager } from 'element-ui/lib/utils/popup';
import { addWindow } from '@/utils/auth';

// v-dialogDrag: 弹窗拖拽
Vue.directive('dialogDrag', {
  bind: function bind(el, binding, vnode, oldVnode) {
    var dialogHeaderEl = el.querySelector('.el-dialog__header');
    var dragDom = el.querySelector('.el-dialog');
    dialogHeaderEl.style.cursor = 'move';

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    var sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
    dialogHeaderEl.onmousedown = function (e) {
      // 鼠标按下，计算当前元素距离可视区的距离
      var disX = e.clientX - dialogHeaderEl.offsetLeft;
      var disY = e.clientY - dialogHeaderEl.offsetTop;
      var dragDomWidth = dragDom.offsetWidth;
      var dragDomHeight = dialogHeaderEl.offsetHeight;
      var screenWidth = document.getElementsByClassName('app-main')[0].clientWidth;
      var screenHeight = document.getElementsByClassName('app-main')[0].clientHeight;
      var minDragDomLeft = dragDom.offsetLeft;
      var maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;
      var minDragDomTop = dragDom.offsetTop;
      var maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight;

      // 获取到的值带px 正则匹配替换
      var styL, styT;

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
      } else {
        styL = +sty.left.replace(/\px/g, '');
        styT = +sty.top.replace(/\px/g, '');
      }
      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        var l = e.clientX - disX;
        var t = e.clientY - disY;

        // 边界处理
        if (-l > minDragDomLeft) {
          l = -minDragDomLeft;
        } else if (l > maxDragDomLeft) {
          l = maxDragDomLeft;
        }
        if (-t > minDragDomTop) {
          t = -minDragDomTop;
        } else if (t > maxDragDomTop) {
          t = maxDragDomTop;
        }

        // 移动当前元素
        dragDom.style.left = "".concat(l + styL, "px");
        dragDom.style.top = "".concat(t + styT, "px");

        // 将此时的位置传出去
        // binding.value({x:e.pageX,y:e.pageY})
      };
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  }
});

// v-popoverDrag: 弹窗拖拽
Vue.directive('popoverDrag', {
  bind: function bind(el, binding, vnode, oldVnode) {
    var popoverHeaderEl = el.querySelector('.el-popover__header');
    var dragDom = el.querySelector('.el-popover');
    popoverHeaderEl.style.cursor = 'move';

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    var sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
    popoverHeaderEl.onmousedown = function (e) {
      // 鼠标按下，计算当前元素距离可视区的距离
      var disX = e.clientX - popoverHeaderEl.offsetLeft;
      var disY = e.clientY - popoverHeaderEl.offsetTop;
      var dragDomWidth = dragDom.offsetWidth;
      var dragDomHeight = popoverHeaderEl.offsetHeight;

      // const screenWidth = document.getElementsByClassName('app-main')[0].clientWidth
      // const screenHeight = document.getElementsByClassName('app-main')[0].clientHeight

      var screenWidth = window.innerWidth;
      var screenHeight = window.innerHeight;

      // let dragLeft = dragDom.offsetLeft
      // let dragParentNode = dragDom.parentNode
      // while (dragParentNode.className !== 'app-main') {
      //
      //     if (dragParentNode.className.indexOf('el-popover') != -1 ){
      //         dragLeft += dragParentNode.offsetLeft
      //     }
      //
      //     //
      //     dragParentNode = dragParentNode.parentNode
      // }
      // const minDragDomLeft = dragLeft

      var minDragDomLeft = dragDom.offsetLeft;
      var maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth;
      var minDragDomTop = dragDom.offsetTop;
      var maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight;

      // 获取到的值带px 正则匹配替换
      var styL, styT;

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100);
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100);
      } else {
        styL = +sty.left.replace(/\px/g, '');
        styT = +sty.top.replace(/\px/g, '');
      }
      document.onmousemove = function (e) {
        // 通过事件委托，计算移动的距离
        var l = e.clientX - disX;
        var t = e.clientY - disY;

        // 边界处理
        if (-l > minDragDomLeft) {
          l = -minDragDomLeft;
        } else if (l > maxDragDomLeft) {
          l = maxDragDomLeft;
        }
        if (-t > minDragDomTop) {
          t = -minDragDomTop;
        } else if (t > maxDragDomTop) {
          t = maxDragDomTop;
        }

        // 移动当前元素
        dragDom.style.left = "".concat(l + styL, "px");
        dragDom.style.top = "".concat(t + styT, "px");

        // 将此时的位置传出去
        // binding.value({x:e.pageX,y:e.pageY})
      };
      document.onmouseup = function (e) {
        document.onmousemove = null;
        document.onmouseup = null;
      };
      dragDom.style.zIndex = PopupManager.nextZIndex();
      addWindow(dragDom.id);
    };
    //保证当前点击的窗口显示在最上层
    // dragDom.onmousedown = (e) => {
    //     dragDom.style.zIndex = PopupManager.nextZIndex()
    //     addWindow(dragDom.id)
    // }
  }
});