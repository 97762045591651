import "core-js/modules/es6.regexp.to-string";
import { getProjectConst } from '@/utils/auth';
var project = {
  // 正式打包命令 npm run build:prod --report
  // 测试运行命令 npm run env

  //系统模式 Product 或 Debug
  SysMode: 'Product',
  // API地址
  API_URL: {
    Debug: 'http://127.0.0.1:8081/big_customer/',
    Product: '/big_customer/'
  }
};
export function config() {
  var tempProject = project;
  tempProject = {};
  tempProject.SysMode = project.SysMode;
  for (var key in project) {
    if (key !== 'SysMode') {
      tempProject[key] = project[key][project.SysMode];
    }
  }
  return tempProject;
}
export function showFileUrl(url, defaultUrl) {
  if (url === null) {
    return defaultUrl;
  }
  if (url !== undefined && url.length > 0) {
    if (url.indexOf('http') !== -1) {
      return url;
    } else {
      if (getProjectConst('BaseImageUrl') == null || getProjectConst('BaseImageUrl').length === 0) {
        return '/' + url.toString();
      } else {
        return getProjectConst('BaseImageUrl') + '/' + url.toString();
      }
    }
  } else {
    return defaultUrl;
  }
}