import _objectSpread from "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import request from '@/utils/request';

// 生成随机单号
export function createOrderNum(pre) {
  return request({
    url: '/public/create_order_num',
    method: 'post',
    data: {
      Pre: pre
    }
  });
}

// 项目参数
export function projectConst(pre) {
  return request({
    url: '/public/project_const',
    method: 'post',
    data: {
      Pre: pre
    }
  });
}

// 项目状态参数
export function projectStatus(pre) {
  return request({
    url: '/public/project_status',
    method: 'post',
    data: {
      Pre: pre
    }
  });
}

// 大客户余额
export function balance(pre) {
  return request({
    url: '/public/balance',
    method: 'post',
    data: {
      Pre: pre
    }
  });
}

// 水票数
export function ticketCount(pre) {
  return request({
    url: '/public/ticket_count',
    method: 'post',
    data: _objectSpread({}, pre)
  });
}

// 项目字典参数
export function projectDict(pre) {
  return request({
    url: '/public/project_dict',
    method: 'post',
    data: {
      Pre: pre
    }
  });
}